import * as React from 'react'

import Page from '../components/Page'
import Container from '../components/Container'
import IndexLayout from '../layouts'
import NavHeader from '../components/products/NavHeader'
import AlienHazeViewer from '../components/products/alien-haze/AlienHazeViewer'
import ContextConsumer from '../components/Context'

const AlienHaze = ({pathContext: { locale }}) => (
  <IndexLayout locale={locale}>
    <ContextConsumer>
      {({ data, set }) => (
    <Page>
      <Container>
        <NavHeader productName={'alien haze'}/>
        <AlienHazeViewer items={data.cartItems} productName={'ALIEN HAZE'} />
      </Container>
    </Page>
      )}
    </ContextConsumer>
  </IndexLayout>
)

export default AlienHaze
