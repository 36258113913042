import * as React from 'react'
import styled from '@emotion/styled'

import Sticky from 'react-stickynode';
import { FormattedMessage } from 'react-intl';
import { fonts } from '../../../styles/variables'

import AddToCart from '../../cart/AddToCart'
import { SRLWrapper } from 'simple-react-lightbox'
import config from 'react-reveal/globals';
import Fade from 'react-reveal/Fade';
import LocalizedLink from '../../links/LocalizedLink'
import Swiper from 'react-id-swiper'
import AhImg1 from './AhImg1'
import AhImg2 from './AhImg2'
import AhImg3 from './AhImg3'
import AhImg4 from './AhImg4'
import AhImg5 from './AhImg5'

const options={

  buttons:{
    showDownloadButton:false
  }
}


import { LilaCert, LilaGlass, LilaStats, LilaWeight } from '../../../constants/lilaIcons'
import ThIcon1 from './ThIcon1'
import ThIcon2 from './ThIcon2'
config({ ssrFadeout: true });


const LilaSticky = styled.div`
width:50%;

 @media screen and (max-width: 1199px) {
width:50%}

  @media screen and (max-width: 977px) {
  display:none;
}
`

const StyledSticky :any = styled(Sticky)`
width:100%;
  @media screen and (max-width: 977px) {
  display:none;
}


`


const LilaMobileDetails = styled.div`
width:100%;
display:none;
margin-top:2rem;
  @media screen and (max-width: 977px) {
  display:flex;
}

`




const LilaMobileSlider =styled.div`
display:none;
position:relative;
z-index:99;

  @media screen and (max-width: 977px) {
  display:flex;
  width:100%;
}


.swiper-button-next{
    background-image: none !important;
}
.swiper-button-prev{
    background-image: none !important;
    background-image: none !important;
}


.swiper-container {
  margin: 0 auto;
  position: relative;
  overflow: hidden;
  list-style: none;
  padding: 0;
  /* Fix of Webkit flickering */
  z-index: 1;
  width:100%;
  height:auto;
}
.swiper-container-no-flexbox .swiper-slide {
  float: left;
}
.swiper-container-vertical > .swiper-wrapper {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
.swiper-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
.swiper-container-android .swiper-slide,
.swiper-wrapper {
  -webkit-transform: translate3d(0px, 0, 0);
  transform: translate3d(0px, 0, 0);
}
.swiper-container-multirow > .swiper-wrapper {
  -webkit-flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
.swiper-container-free-mode > .swiper-wrapper {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
  margin: 0 auto;
}
.swiper-slide {
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  width: 100%;
  height: 100%;
  position: relative;
  -webkit-transition-property: -webkit-transform;
  transition-property: -webkit-transform;
  -o-transition-property: transform;
  transition-property: transform;
  transition-property: transform, -webkit-transform;
}
.swiper-slide-invisible-blank {
  visibility: hidden;
}
/* Auto Height */
.swiper-container-autoheight,
.swiper-container-autoheight .swiper-slide {
  height: auto;
}
.swiper-container-autoheight .swiper-wrapper {
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  -webkit-transition-property: height, -webkit-transform;
  transition-property: height, -webkit-transform;
  -o-transition-property: transform, height;
  transition-property: transform, height;
  transition-property: transform, height, -webkit-transform;
}
/* 3D Effects */
.swiper-container-3d {
  -webkit-perspective: 1200px;
  perspective: 1200px;
}
.swiper-container-3d .swiper-wrapper,
.swiper-container-3d .swiper-slide,
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom,
.swiper-container-3d .swiper-cube-shadow {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.swiper-container-3d .swiper-slide-shadow-left,
.swiper-container-3d .swiper-slide-shadow-right,
.swiper-container-3d .swiper-slide-shadow-top,
.swiper-container-3d .swiper-slide-shadow-bottom {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 10;
}
.swiper-container-3d .swiper-slide-shadow-left {
  background-image: -webkit-gradient(linear, right top, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-right {
  background-image: -webkit-gradient(linear, left top, right top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(left, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to right, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-top {
  background-image: -webkit-gradient(linear, left bottom, left top, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
.swiper-container-3d .swiper-slide-shadow-bottom {
  background-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0, 0, 0, 0.5)), to(rgba(0, 0, 0, 0)));
  background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: -o-linear-gradient(top, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0));
}
/* IE10 Windows Phone 8 Fixes */
.swiper-container-wp8-horizontal,
.swiper-container-wp8-horizontal > .swiper-wrapper {
  -ms-touch-action: pan-y;
  touch-action: pan-y;
}
.swiper-container-wp8-vertical,
.swiper-container-wp8-vertical > .swiper-wrapper {
  -ms-touch-action: pan-x;
  touch-action: pan-x;
}
.swiper-button-prev,
.swiper-button-next {
  position: absolute;
  display:flex;
  justify-content:center;
  top: 0;
  width: 27px;
  height: 100%;
  margin-top: 0px;
  z-index: 10;
  cursor: pointer;
  background-size: 27px 44px;
  background-position: center;
  background-repeat: no-repeat;
}
.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}
.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23fff'%2F%3E%3C%2Fsvg%3E");
  left: 0px;
  right: auto;
}
.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23fff'%2F%3E%3C%2Fsvg%3E");
  right: 0px;
  left: auto;
}
.swiper-button-prev.swiper-button-white,
.swiper-container-rtl .swiper-button-next.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-white,
.swiper-container-rtl .swiper-button-prev.swiper-button-white {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23ffffff'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-prev.swiper-button-black,
.swiper-container-rtl .swiper-button-next.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M0%2C22L22%2C0l2.1%2C2.1L4.2%2C22l19.9%2C19.9L22%2C44L0%2C22L0%2C22L0%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-next.swiper-button-black,
.swiper-container-rtl .swiper-button-prev.swiper-button-black {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20viewBox%3D'0%200%2027%2044'%3E%3Cpath%20d%3D'M27%2C22L27%2C22L5%2C44l-2.1-2.1L22.8%2C22L2.9%2C2.1L5%2C0L27%2C22L27%2C22z'%20fill%3D'%23000000'%2F%3E%3C%2Fsvg%3E");
}
.swiper-button-lock {
  display: none;
}
.swiper-pagination {
  position: absolute;
  text-align: center;
  -webkit-transition: 300ms opacity;
  -o-transition: 300ms opacity;
  transition: 300ms opacity;
  -webkit-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  z-index: 10;
}
.swiper-pagination.swiper-pagination-hidden {
  opacity: 0;
}
/* Common Styles */
.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-container-horizontal > .swiper-pagination-bullets {
  bottom: 10px;
  left: 0;
  width: 100%;
}
/* Bullets */
.swiper-pagination-bullets-dynamic {
  overflow: hidden;
  font-size: 0;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
  position: relative;
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-main {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next {
  -webkit-transform: scale(0.66);
  -ms-transform: scale(0.66);
  transform: scale(0.66);
}
.swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next {
  -webkit-transform: scale(0.33);
  -ms-transform: scale(0.33);
  transform: scale(0.33);
}
.swiper-pagination-bullet {
  width: 8px;
  height: 8px;
  display: inline-block;
  border-radius: 100%;
  background: var(--haase);
  opacity: 0.2;
}
button.swiper-pagination-bullet {
  border: none;
  margin: 0;
  padding: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.swiper-pagination-clickable .swiper-pagination-bullet {
  cursor: pointer;
}
.swiper-pagination-bullet-active {
  opacity: 1;
  background: var(--haase);
}
.swiper-container-vertical > .swiper-pagination-bullets {
  right: 10px;
  top: 50%;
  -webkit-transform: translate3d(0px, -50%, 0);
  transform: translate3d(0px, -50%, 0);
}
.swiper-container-vertical > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 6px 0;
  display: block;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  width: 8px;
}
.swiper-container-vertical > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  display: inline-block;
  -webkit-transition: 200ms top, 200ms -webkit-transform;
  transition: 200ms top, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top;
  transition: 200ms transform, 200ms top, 200ms -webkit-transform;
}
.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 4px;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic {
  left: 50%;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  white-space: nowrap;
}
.swiper-container-horizontal > .swiper-pagination-bullets.swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms left, 200ms -webkit-transform;
  transition: 200ms left, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left;
  transition: 200ms transform, 200ms left, 200ms -webkit-transform;
}
.swiper-container-horizontal.swiper-container-rtl > .swiper-pagination-bullets-dynamic .swiper-pagination-bullet {
  -webkit-transition: 200ms right, 200ms -webkit-transform;
  transition: 200ms right, 200ms -webkit-transform;
  -o-transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right;
  transition: 200ms transform, 200ms right, 200ms -webkit-transform;
}
/* Progress */
.swiper-pagination-progressbar {
  background: rgba(0, 0, 0, 0.25);
  position: absolute;
}
.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  -webkit-transform: scale(0);
  -ms-transform: scale(0);
  transform: scale(0);
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
}
.swiper-container-rtl .swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
}
.swiper-container-horizontal > .swiper-pagination-progressbar,
.swiper-container-vertical > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 100%;
  height: 4px;
  left: 0;
  top: 0;
}
.swiper-container-vertical > .swiper-pagination-progressbar,
.swiper-container-horizontal > .swiper-pagination-progressbar.swiper-pagination-progressbar-opposite {
  width: 4px;
  height: 100%;
  left: 0;
  top: 0;
}
.swiper-pagination-white .swiper-pagination-bullet-active {
  background: #ffffff;
}
.swiper-pagination-progressbar.swiper-pagination-white {
  background: rgba(255, 255, 255, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-white .swiper-pagination-progressbar-fill {
  background: #ffffff;
}
.swiper-pagination-black .swiper-pagination-bullet-active {
  background: #000000;
}
.swiper-pagination-progressbar.swiper-pagination-black {
  background: rgba(0, 0, 0, 0.25);
}
.swiper-pagination-progressbar.swiper-pagination-black .swiper-pagination-progressbar-fill {
  background: #000000;
}
.swiper-pagination-lock {
  display: none;
}
/* Scrollbar */
.swiper-scrollbar {
  border-radius: 10px;
  position: relative;
  -ms-touch-action: none;
  background: rgba(0, 0, 0, 0.1);
}
.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  left: 1%;
  bottom: 3px;
  z-index: 50;
  height: 5px;
  width: 98%;
}
.swiper-container-vertical > .swiper-scrollbar {
  position: absolute;
  right: 3px;
  top: 1%;
  z-index: 50;
  width: 5px;
  height: 98%;
}
.swiper-scrollbar-drag {
  height: 100%;
  width: 100%;
  position: relative;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  left: 0;
  top: 0;
}
.swiper-scrollbar-cursor-drag {
  cursor: move;
}
.swiper-scrollbar-lock {
  display: none;
}
.swiper-zoom-container {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.swiper-zoom-container > img,
.swiper-zoom-container > svg,
.swiper-zoom-container > canvas {
  max-width: 100%;
  max-height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.swiper-slide-zoomed {
  cursor: move;
}
/* Preloader */
.swiper-lazy-preloader {
  width: 42px;
  height: 42px;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-left: -21px;
  margin-top: -21px;
  z-index: 10;
  -webkit-transform-origin: 50%;
  -ms-transform-origin: 50%;
  transform-origin: 50%;
  -webkit-animation: swiper-preloader-spin 1s steps(12, end) infinite;
  animation: swiper-preloader-spin 1s steps(12, end) infinite;
}
.swiper-lazy-preloader:after {
  display: block;
  content: '';
  width: 100%;
  height: 100%;
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%236c6c6c'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
  background-position: 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}
.swiper-lazy-preloader-white:after {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg%20viewBox%3D'0%200%20120%20120'%20xmlns%3D'http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg'%20xmlns%3Axlink%3D'http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink'%3E%3Cdefs%3E%3Cline%20id%3D'l'%20x1%3D'60'%20x2%3D'60'%20y1%3D'7'%20y2%3D'27'%20stroke%3D'%23fff'%20stroke-width%3D'11'%20stroke-linecap%3D'round'%2F%3E%3C%2Fdefs%3E%3Cg%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(30%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(60%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(90%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(120%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.27'%20transform%3D'rotate(150%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.37'%20transform%3D'rotate(180%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.46'%20transform%3D'rotate(210%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.56'%20transform%3D'rotate(240%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.66'%20transform%3D'rotate(270%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.75'%20transform%3D'rotate(300%2060%2C60)'%2F%3E%3Cuse%20xlink%3Ahref%3D'%23l'%20opacity%3D'.85'%20transform%3D'rotate(330%2060%2C60)'%2F%3E%3C%2Fg%3E%3C%2Fsvg%3E");
}
@-webkit-keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes swiper-preloader-spin {
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* a11y */
.swiper-container .swiper-notification {
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  opacity: 0;
  z-index: -1000;
}
.swiper-container-fade.swiper-container-free-mode .swiper-slide {
  -webkit-transition-timing-function: ease-out;
  -o-transition-timing-function: ease-out;
  transition-timing-function: ease-out;
}
.swiper-container-fade .swiper-slide {
  pointer-events: none;
  -webkit-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}
.swiper-container-fade .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-fade .swiper-slide-active,
.swiper-container-fade .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube {
  overflow: visible;
}
.swiper-container-cube .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
  visibility: hidden;
  -webkit-transform-origin: 0 0;
  -ms-transform-origin: 0 0;
  transform-origin: 0 0;
  width: 100%;
  height: 100%;
}
.swiper-container-cube .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-cube.swiper-container-rtl .swiper-slide {
  -webkit-transform-origin: 100% 0;
  -ms-transform-origin: 100% 0;
  transform-origin: 100% 0;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-cube .swiper-slide-active,
.swiper-container-cube .swiper-slide-next,
.swiper-container-cube .swiper-slide-prev,
.swiper-container-cube .swiper-slide-next + .swiper-slide {
  pointer-events: auto;
  visibility: visible;
}
.swiper-container-cube .swiper-slide-shadow-top,
.swiper-container-cube .swiper-slide-shadow-bottom,
.swiper-container-cube .swiper-slide-shadow-left,
.swiper-container-cube .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-cube .swiper-cube-shadow {
  position: absolute;
  left: 0;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 0.6;
  -webkit-filter: blur(50px);
  filter: blur(50px);
  z-index: 0;
}
.swiper-container-flip {
  overflow: visible;
}
.swiper-container-flip .swiper-slide {
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  z-index: 1;
}
.swiper-container-flip .swiper-slide .swiper-slide {
  pointer-events: none;
}
.swiper-container-flip .swiper-slide-active,
.swiper-container-flip .swiper-slide-active .swiper-slide-active {
  pointer-events: auto;
}
.swiper-container-flip .swiper-slide-shadow-top,
.swiper-container-flip .swiper-slide-shadow-bottom,
.swiper-container-flip .swiper-slide-shadow-left,
.swiper-container-flip .swiper-slide-shadow-right {
  z-index: 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.swiper-container-coverflow .swiper-wrapper {
  /* Windows 8 IE 10 fix */
  -ms-perspective: 1200px;
}


`



const LilaTop = styled.div`
width:100%;
height:30vh;
`

const LilaViewer = styled.div`
width:100%;
display:flex;
padding-top:132px;


  @media screen and (max-width: 977px) {
  display:block;
}

`

const LilaSlider = styled.div`
width:50%;

 @media screen and (max-width: 1390px) {
width:68%}

  @media screen and (max-width: 977px) {
  display:none;
}
`

const LilaDetails = styled.div`
width:100%;
padding-left:10rem;
padding-right:5rem;

 @media screen and (max-width: 1390px) {
padding-left:3rem;
padding-right:3rem;

}





`

const LilaGrams = styled.div`
font-family:${fonts.bcExtraLight};

`

const LilaName = styled.div`
`

const LilAmount = styled.div`
font-family:${fonts.bcExtraLight};

`

const LilaDetailsHeader = styled.div`
width:220px;
display:flex;
justify-content:space-between;

`


const ProductVariations = styled.div`
display:flex;
.selected{
background:var(--haase);
border:1px solid #7e287e;

color:white;
}

`

const TwoGrams = styled.div`
cursor:pointer;
font-family:${fonts.bcExtraLight};

width:2.2rem;
height:2.2rem;
border-radius:50%;
background:var(--bg);
border:1px solid var(--border);
justify-content:center;
align-items:center;
display:flex;
margin-right:1rem;


`

const FiveGrams = styled.div`
font-family:${fonts.bcExtraLight};

cursor:pointer;
width:2.2rem;
height:2.2rem;
border-radius:50%;
background:var(--bg);
border:1px solid var(--border);
justify-content:center;
align-items:center;
display:flex;

.selected{
background:purple;
color:white;
}
`




const LilaInfo = styled.div`
margin-top:2rem;
font-size:1rem;
font-family:${fonts.bcHairLine};
`

const LilaLine = styled.div`
width:220px;
display:flex;
justify-content:space-between;
margin-top:1rem;

`

const LilaContent = styled.div`
margin-bottom:1rem;
`



const LilaTabSlide = styled.div`
height:2px;
background:var(--hase);
transition:all .4s ease;


`

const LilaTab=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
margin-right:3rem;
font-family:${fonts.bcExtraLight};

 @media screen and (max-width: 1199px) {
font-size:0.7rem;
margin-right:0rem;
}

`

const LilaTab_2=styled.div`
display:flex;
flex-direction:column;
justify-content:center;
align-items:center;
font-family:${fonts.bcExtraLight};

 @media screen and (max-width: 1199px) {
font-size:0.7rem;
margin-left:3rem;

}

`

const LilaTabs=styled.div`
width:auto;
display:flex;
margin-top:2.2rem;

 @media screen and (max-width: 1199px) {
width:100%;
}



`



const LilaLightbox = styled(SRLWrapper)`
#SRLLightbox{

z-index:99999999999;
}
`


const LilaZoom = styled.div`
    cursor:zoom-in;

    img{
    }

`

const LilaPrev = styled.div`
display:flex;
justify-content:flex-start;
align-items:center;
height:100%;
position:absolute;
opacity:.5;
width:14px;
transition:all .3s ease;

svg{
width:10px;
}
fill:var(--haase);

:hover{
background:var(--headerBg);
}

`

const LilaNext = styled.div`
display:flex;
justify-content:flex-end;
align-items:center;
height:100%;
position:absolute;
opacity:.3;
width:14px;
transition:all .3s ease;
svg{
width:10px;
}
fill:var(--haase);

:hover{
background:var(--headerBg);
}

`

const LilaInfoTable = styled.div`
margin-top:2rem;
display:flex;
padding:5%;
//border: 1px solid var(--hase);
font-size:0.9rem;

  @media screen and (max-width: 454px) {
font-size:0.8rem;
padding:0;
}
`

const LilaRow = styled.tr`
height:60px;
display:flex;
align-items:center;




:hover{
.cls-2{
fill:var(--hase) !important;
}
}



`

const LilaCell = styled.td`
border:none;

  @media screen and (max-width: 454px) {
svg{
width:12px !important;
}
}


svg{
width:24px;

}





`

const LilaCell1 = styled.td`
border:none;
width:50px;


svg{
fill:var(--txt);
width:30px;
height:30px;
}
`

const LilaSection = styled.div`

`

const LilaLeft = styled.div`
margin-right:15%;
`

const LilaShippingLink = styled(LocalizedLink)`
color:var(--hase);
font-family:${fonts.bcExtraLight};
:hover{
text-decoration:underline var(--hase);
}

`

const LilaPaymentsLink = styled(LocalizedLink)`
color:var(--hase);
font-family:${fonts.bcExtraLight};

:hover{
text-decoration:underline var(--hase);
}


`


const Brand = styled.div`
width:100%;
font-size:0.8rem;
opacity:1;
transition:all .4s ease;
//margin-left:0.3rem;
padding:5%;
margin-left:0.7rem;
font-family:${fonts.bcHairLine};

a{
}
  @media screen and (max-width: 454px) {
padding:0;
}



`
const TomHemps = styled.div`
display:flex;
width:100%;
align-items:center;
font-size:1rem;
margin-top:0.6rem;

`

const LilaBtn = styled.div`
border: 1px solid #578280;
border-radius:18px;
height:2.2rem;
display:flex;
justify-content:center;
padding: 0.0rem 0.9rem;
color:#578280;
font-family:${fonts.bcExtraLight};
display:flex;
justify-content:center;
align-items:center;

`

const LilaLight = styled.div`
display:var(--light);
`

const LilaDark = styled.div`
display:var(--dark);
`

class AlienHazeViewer extends React.Component {
  constructor(props: any) {
    super(props)
    this.state = {
      twoGramsSelected:true,
      fiveGramsSelected:false,
      amount:25,
      count:1,
      highlightedSlice: 1,
      descrOpen:true
    }
    this.selectTwoGrams=this.selectTwoGrams.bind(this)
    this.selectFiveGrams=this.selectFiveGrams.bind(this)
    this.openDescr=this.openDescr.bind(this)
    this.openAdditional=this.openAdditional.bind(this)
  }


  openDescr(){
    this.setState({
      descrOpen:true
    })
  }

  openAdditional(){
    this.setState({
      descrOpen:false
    })
  }
  selectTwoGrams() {
    this.setState({
      twoGramsSelected:true,
      fiveGramsSelected:false,
      amount: this.state.count*25
    })
  }

  selectFiveGrams() {
    this.setState({
      twoGramsSelected:false,
      fiveGramsSelected:true,
      amount: this.state.count*50

    })
  }
  handleMouseOver(data) {
    this.setState({
      highlightedSlice: data.data.id
    });
  }

  handleMouseOut() {
    this.setState({
      highlightedSlice: 99999
    });
  }


  componentDidMount() {

  }



  render() {


    const params = {

      keyboard: {
        enabled: true,
        onlyInViewport: true,
      },
      autoplay: {
        delay: 6000,
        disableOnInteraction: true
      },
      pagination: {
        el: '.swiper-pagination',
        clickable: true,


      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      renderPrevButton: () => <LilaPrev className="swiper-button-prev">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44"><path d="M0,22L22,0l2.1,2.1L4.2,22l19.9,19.9L22,44L0,22L0,22L0,22z"></path></svg>
      </LilaPrev>,
      renderNextButton: () => <LilaNext className="swiper-button-next">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 44"><path d="M27,22L27,22L5,44l-2.1-2.1L22.8,22L2.9,2.1L5,0L27,22L27,22z"></path></svg>
      </LilaNext>,
    }
    return(


        <LilaViewer id={'component'}>


<LilaSlider className={'lila-slider'}>
  <LilaLightbox options={options} id={'_lila_light'} className={'lila-light'}>
    <LilaZoom>
      <AhImg1/>
      <AhImg2/>
      <AhImg3/>
      <AhImg4/>
      <AhImg5/>


    </LilaZoom>
  </LilaLightbox>

</LilaSlider>
<LilaSticky>
  <LilaTop></LilaTop>
          <StyledSticky innerZ={9999999} enabled={true} top={160} bottomBoundary={'.lila-slider'}>
            <LilaDetails>
              <Fade top>

              <LilaDetailsHeader>
              <LilaName>
              {this.props.productName}
              </LilaName>
              <LilAmount>{this.state.amount.toString()}€ </LilAmount>
              <LilaGrams>{!this.state.twoGramsSelected ? <FormattedMessage id={'5grams'}/>:<FormattedMessage id={'2grams'}/>}</LilaGrams>
              </LilaDetailsHeader>
             <LilaLine>
              <ProductVariations>
                <TwoGrams onClick={this.selectTwoGrams} className={this.state.twoGramsSelected?"grams selected":"grams"}>2g</TwoGrams>
                <FiveGrams onClick={this.selectFiveGrams} className={this.state.twoGramsSelected?"grams":"grams selected"}>5g</FiveGrams>
              </ProductVariations>
               {((this.props.items[2].stock < 1 && this.state.twoGramsSelected) || (this.props.items[3].stock < 1 && this.state.fiveGramsSelected)) &&
               <LilaBtn><FormattedMessage id={'oos'}/></LilaBtn>
               }

               {((this.props.items[2].stock > 0 && this.state.twoGramsSelected) || (this.props.items[3].stock > 0 && this.state.fiveGramsSelected)) &&
               <AddToCart id={this.state.twoGramsSelected?2:3}/>

               }
             </LilaLine>
              <LilaTabs>

                <LilaTab style={{opacity:this.state.descrOpen?1:.5}} onClick={this.openDescr}>
                  <FormattedMessage id={'descr_0'}/>
                  <LilaTabSlide style={{width:!this.state.descrOpen?'0%':'100%'}}/>
                </LilaTab>
                <LilaTab_2 style={{opacity:!this.state.descrOpen?1:.5}} onClick={this.openAdditional}>
                  <FormattedMessage id={'descr_1'}/>
                  <LilaTabSlide style={{width:this.state.descrOpen?'0%':'100%'}}/>
                </LilaTab_2>


              </LilaTabs>
          </Fade>

              {this.state.descrOpen &&
              <Fade ssrFadeOut right >
              <LilaInfo>
                <LilaContent>
                  <FormattedMessage id={'content'}></FormattedMessage></LilaContent>
                <FormattedMessage id={'ah_1'}></FormattedMessage><br/><br/>
                <FormattedMessage id={'ah_3'}></FormattedMessage><br/><br/>

                <LilaInfoTable>
                  <LilaLeft>
                  <LilaRow>
                    <LilaCell1> <LilaStats></LilaStats></LilaCell1>
                    <LilaCell> CBD 5-7% <br/> {'THC < 0.2%'}</LilaCell>

                  </LilaRow>

                    <LilaRow>
                      <LilaCell1> <LilaCert></LilaCert></LilaCell1>
                      <LilaCell> <FormattedMessage id={'strainInfo_1'}></FormattedMessage></LilaCell>

                    </LilaRow>
                    <LilaRow>

                    </LilaRow>
                  </LilaLeft>
                  <LilaSection>
                    <LilaRow>
                      <LilaCell1> <LilaWeight></LilaWeight></LilaCell1>
                      <LilaCell> {'2g | 5g'}</LilaCell>

                    </LilaRow>

                  <LilaRow>
                    <LilaCell1> <LilaGlass></LilaGlass></LilaCell1>
                    <LilaCell> <FormattedMessage id={'strainInfo_2'}></FormattedMessage></LilaCell>

                  </LilaRow>

                    <LilaRow>

                    </LilaRow>
                  </LilaSection>

                </LilaInfoTable>
              </LilaInfo>
                <Brand>
                  brought to you by
                  <a href={'https://tomhemps.com/'} target="_blank">
                  <TomHemps>
                    <LilaDark><ThIcon1/></LilaDark>
                    <LilaLight><ThIcon2/></LilaLight>
                  </TomHemps>
                  </a>
                </Brand>
              </Fade>
              }
              {!this.state.descrOpen &&
              <Fade right >
              <LilaInfo>

                  <FormattedMessage id={'additional_1'}></FormattedMessage><br/>
                <FormattedMessage id={'additional_2'}></FormattedMessage><br/><br/>
                <FormattedMessage id={'additional_3'}></FormattedMessage><br/><br/>
                <FormattedMessage id={'additional_4'} ></FormattedMessage> <LilaShippingLink to={'/zahlungsarten'}><FormattedMessage id={'menu_tab_4_4'}/></LilaShippingLink> & <LilaPaymentsLink to={'/versand'}><FormattedMessage id={'menu_tab_4_3'}/></LilaPaymentsLink>
              </LilaInfo>
              </Fade>

              }

            </LilaDetails>
          </StyledSticky>
</LilaSticky>

          <LilaMobileSlider>

            <Swiper {...params} >
              <div key="1"><AhImg1/></div>
              <div key="2"><AhImg2/></div>
              <div key="3"><AhImg3/></div>
              <div key="4"><AhImg4/></div>
              <div key="5"><AhImg5/></div>

            </Swiper>

          </LilaMobileSlider>
          <LilaMobileDetails>
            <LilaDetails>
              <Fade top>

                <LilaDetailsHeader>
                  <LilaName>
                    {this.props.productName}
                  </LilaName>
                  <LilAmount>{this.state.amount.toString()}€ </LilAmount>
                  <LilaGrams>{!this.state.twoGramsSelected ? <FormattedMessage id={'5grams'}/>:<FormattedMessage id={'2grams'}/>}</LilaGrams>
                </LilaDetailsHeader>
                <LilaLine>
                  <ProductVariations>
                    <TwoGrams onClick={this.selectTwoGrams} className={this.state.twoGramsSelected?"grams selected":"grams"}>2g</TwoGrams>
                    <FiveGrams onClick={this.selectFiveGrams} className={this.state.twoGramsSelected?"grams":"grams selected"}>5g</FiveGrams>
                  </ProductVariations>
                  <AddToCart id={this.state.twoGramsSelected?2:3}/>

                </LilaLine>
                <LilaTabs>

                  <LilaTab style={{opacity:this.state.descrOpen?1:.5}} onClick={this.openDescr}>
                    <FormattedMessage id={'descr_0'}/>
                    <LilaTabSlide style={{width:!this.state.descrOpen?'0%':'100%'}}/>
                  </LilaTab>
                  <LilaTab_2 style={{opacity:!this.state.descrOpen?1:.5}} onClick={this.openAdditional}>
                    <FormattedMessage id={'descr_1'}/>
                    <LilaTabSlide style={{width:this.state.descrOpen?'0%':'100%'}}/>
                  </LilaTab_2>


                </LilaTabs>
              </Fade>

              {this.state.descrOpen &&
              <Fade ssrFadeOut right >
                <LilaInfo>
                  <LilaContent>
                    <FormattedMessage id={'content'}></FormattedMessage></LilaContent>
                  <FormattedMessage id={'ah_1'}></FormattedMessage><br/><br/>
                  <FormattedMessage id={'ah_3'}></FormattedMessage><br/><br/>

                  <LilaInfoTable>
                    <LilaLeft>
                      <LilaRow>
                        <LilaCell1> <LilaStats></LilaStats></LilaCell1>
                        <LilaCell> CBD 5-7% <br/> {'THC < 0.2%'}</LilaCell>

                      </LilaRow>

                      <LilaRow>
                        <LilaCell1> <LilaCert></LilaCert></LilaCell1>
                        <LilaCell> <FormattedMessage id={'strainInfo_1'}></FormattedMessage></LilaCell>

                      </LilaRow>
                      <LilaRow>

                      </LilaRow>
                    </LilaLeft>
                    <LilaSection>
                      <LilaRow>
                        <LilaCell1> <LilaWeight></LilaWeight></LilaCell1>
                        <LilaCell> {'2g | 5g'}</LilaCell>

                      </LilaRow>

                      <LilaRow>
                        <LilaCell1> <LilaGlass></LilaGlass></LilaCell1>
                        <LilaCell> <FormattedMessage id={'strainInfo_2'}></FormattedMessage></LilaCell>

                      </LilaRow>

                      <LilaRow>

                      </LilaRow>

                    </LilaSection>
                  </LilaInfoTable>

                </LilaInfo>
                <Brand>
                  brought to you by
                  <a href={'https://tomhemps.com/'} target="_blank">
                  <TomHemps>
                    <LilaDark><ThIcon1/></LilaDark>
                    <LilaLight><ThIcon2/></LilaLight>                  </TomHemps>
                  </a>
                </Brand>
              </Fade>
              }
              {!this.state.descrOpen &&
              <Fade right >
                <LilaInfo>

                  <FormattedMessage id={'additional_1'}></FormattedMessage><br/>
                  <FormattedMessage id={'additional_2'}></FormattedMessage><br/><br/>
                  <FormattedMessage id={'additional_3'}></FormattedMessage><br/><br/>
                  <FormattedMessage id={'additional_4'} ></FormattedMessage> <LilaShippingLink to={'/zahlungsarten'}><FormattedMessage id={'menu_tab_4_4'}/></LilaShippingLink> & <LilaPaymentsLink to={'/versand'}><FormattedMessage id={'menu_tab_4_3'}/></LilaPaymentsLink>
                </LilaInfo>
              </Fade>

              }

            </LilaDetails>

          </LilaMobileDetails>
    </LilaViewer>



    )
  }
}


export default AlienHazeViewer


//<P><Zoom delay={4200} right cascade>CBD BLÜTEN</Zoom></P>
